import api from './MeshApi';
import { useState } from 'react';

const DeleteHumans = () => {
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    setError('');
    setSuccessMessage('');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError('');
    setSuccessMessage('');
  };

  const handleDeleteByEmail = async () => {
    if (email) {
      try {
        await api.deleteHumanByEmail(email);
        setSuccessMessage('Human deleted successfully');
        setEmail('');
        setPhone('');
      } catch (error: any) {
        console.log('Failed to delete human by email:', error);
        setError(error.toString());
      }
    }
  };

  const handleDeleteByPhoneNumber = async () => {
    if (phone) {
      try {
        await api.deleteHumanByPhoneNumber(phone);
        setSuccessMessage('Human deleted successfully');
        setEmail('');
        setPhone('');
      } catch (error: any) {
        console.log('Failed to delete human by phone number:', error);
        setError(error.toString());
      }
    }
  };

  return (
    <div className="form-container">
      <div className="form-group">
        <label htmlFor="phone">Phone:</label>
        <input type="tel" id="phone" name="phone" className="form-input" value={phone} onChange={handlePhoneChange} />
        <button disabled={!phone} type="button" className="form-button" onClick={handleDeleteByPhoneNumber}>
          Delete Phone
        </button>
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" className="form-input" value={email} onChange={handleEmailChange} />
        <button disabled={!email} type="button" className="form-button" onClick={handleDeleteByEmail}>
          Delete Email
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default DeleteHumans;

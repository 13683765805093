import * as x509 from '@peculiar/x509';
import authService, { SavedTokens } from './AuthService';

export interface GetRotateKeysResponseData {
  trustee_stem_id_status: RotationStatusInfoJsonData;
  trustee_stem_cell_key_last_update_timestamp?: Date;
  entity_trustee_stem_id_status?: RotationStatusInfoJsonData;
  entity_trustee_entity_stem_ids_status?: RotationStatusRunningInfoJsonData;
  entity_trustee_stem_cell_key_last_update_timestamp?: Date;
}

interface RotationStatusInfoJsonData {
  current_version: number;
  rotation_status?: RotationStatusRunningInfoJsonData;
  stem_id_last_update_timestamp?: Date;
}

interface RotationStatusRunningInfoJsonData {
  node_rotating: string;
  started_at_timestamp: string;
  last_status_timestamp: string;
}

export interface ConfigJsonData {
  name: string;
  value?: string;
  is_secret: boolean;
}

export interface ConfigReplyJsonData {
  config: ConfigJsonData[];
}

export interface BuildInformationJsonData {
  repository_url: string;
  branch: string;
  owner: string;
  repo: string;
  commit_sha: string;
  commit_message: string;
  create_time: number;
  build_start_time?: string;
  status: string;
  node_building?: string;
  last_status_update?: string;
  last_status_message?: string;
}

export interface DeploymentContainerStatusJsonData {
  alive: boolean;
  last_failure_reason?: string;
  num_restarts: number;
  create_time?: string;
  last_start_time?: string;
  last_exit_time?: string;
  container_id: string;
  container_name?: string;
}

export interface DeploymentStatusJsonData {
  actor_id: string;
  actor_name: string;
  container_repo: string;
  container_version: string;
  container_status?: DeploymentContainerStatusJsonData;
}

export interface DeploymentStatusesJsonData {
  host: string;
  statuses: DeploymentStatusJsonData[];
  update_time: string;
}

export interface FactoryRecord {
  id: string;
}

export interface CertificateRecordIntermediateData {
  cert: string;
  display_name: string;
}

export interface CertificateRecordExportData {
  subject: string;
  pubkey_algorithm_name: string;
  pubkey_algorithm_additional_info: string;
  signature_algorithm_hash: string;
  creation: Date;
  expiration: Date;
  parent: string;
}

export interface CertificateRecords {
  end_entities: Record<string, CertificateRecordExportData>;
  intermediates: Record<string, CertificateRecordExportData>;
  root: Record<string, CertificateRecordExportData>;
}

class MeshApi {
  async getRotateKeysStatus(trusteeName: string): Promise<GetRotateKeysResponseData> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);
    let response = await fetch(`${urlBase}/entities/factory/${id}/get_rotate_keys_status`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
      body: JSON.stringify({ destination_trustee_name: trusteeName }),
    });
    if (response.status !== 200) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    let data = await response.json();
    if (response.status !== 200 || data.error) {
      throw new Error(`Error from server: ${data.error || `Request failed with status ${response.status}`}`);
    }
    return data as GetRotateKeysResponseData;
  }

  async rotateKeys(trusteeName: string, rotateStemCellKey: boolean, rotateTrusteeStemId: boolean): Promise<void> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);

    let response = await fetch(`${urlBase}/entities/factory/${id}/rotate_keys`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
      body: JSON.stringify({
        destination_trustee_name: trusteeName,
        rotate_stem_cell_key: rotateStemCellKey,
        rotate_trustee_stem_id: rotateTrusteeStemId,
      }),
    });
    if (response.status !== 200) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    let data = await response.json();
    if (response.status !== 200 || data.error) {
      throw new Error(`Error from server: ${data.error || `Request failed with status ${response.status}`}`);
    }
  }

  async getConfiguration(agentName: string, storedAtParent: boolean): Promise<ConfigReplyJsonData> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);
    let response = await fetch(`${urlBase}/entities/factory/${id}/get_configuration`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
      body: JSON.stringify({ destination_agent_name: agentName, stored_at_parent: storedAtParent }),
    });
    if (response.status !== 200) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    let data = await response.json();
    if (response.status !== 200 || data.error) {
      throw new Error(`Error from server: ${data.error || `Request failed with status ${response.status}`}`);
    }
    return data as ConfigReplyJsonData;
  }

  async updateConfiguration(
    agentName: string,
    storeAtParent: boolean,
    name: string,
    value: string,
    isSecret: boolean,
  ): Promise<void> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);

    let response = await fetch(`${urlBase}/entities/factory/${id}/update_configuration`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
      body: JSON.stringify({
        destination_agent_name: agentName,
        store_at_parent: storeAtParent,
        config: [{ name, value: value ? value : null, is_secret: isSecret }],
      }),
    });
    if (response.status !== 200) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    let data = await response.json();
    if (response.status !== 200 || data.error) {
      throw new Error(`Error from server: ${data.error || `Request failed with status ${response.status}`}`);
    }
  }

  async deleteHumanByEmail(email: string): Promise<void> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let response = await fetch(`${urlBase}/entities/human/admin_delete`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (response.status !== 200) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    let data = await response.json();
    if (response.status !== 200 || data.error) {
      throw new Error(`Error from server: ${data.error || `Request failed with status ${response.status}`}`);
    }
  }

  async deleteHumanByPhoneNumber(phone: string): Promise<void> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let response = await fetch(`${urlBase}/entities/human/adminDelete`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone }),
    });
    if (response.status !== 200) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    let data = await response.json();
    if (response.status !== 200 || data.error) {
      throw new Error(`Error from server: ${data.error || `Request failed with status ${response.status}`}`);
    }
  }

  async getDeployments(): Promise<DeploymentStatusesJsonData[]> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);
    let getData = await fetch(`${urlBase}/entities/factory/${id}/deployment_status`, {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
    });
    return (await getData.json()) as DeploymentStatusesJsonData[];
  }

  async getBuilds(offset: number, limit: number): Promise<BuildInformationJsonData[]> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);
    let getData = await fetch(`${urlBase}/entities/factory/${id}/get_builds`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
      body: JSON.stringify({ offset, limit, order_descending: true }),
    });
    return (await getData.json()) as BuildInformationJsonData[];
  }

  async getFactoryLinkCode(tokens: SavedTokens): Promise<string> {
    if (tokens.factoryLinkCode) {
      return tokens.factoryLinkCode;
    }
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    let data = await fetch(`${urlBase}/entities/factory`, {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
    });
    let factory_records = (await data.json()) as FactoryRecord[];
    let id = '';
    if (factory_records.length === 0) {
      let createData = await fetch(`${urlBase}/entities/factory`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.idToken}`,
        },
      });
      let createResponse = (await createData.json()) as FactoryRecord;
      id = createResponse.id;
    } else {
      id = factory_records[0].id;
    }
    authService.setFactoryLinkCode(tokens, id);
    return id;
  }

  async resubmitBuilt(commitSha: string): Promise<void> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);
    await fetch(`${urlBase}/entities/factory/${id}/update_build`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
      body: JSON.stringify({ commit_sha: commitSha, build_status: 'Pending' }),
    });
  }

  private static subjectName(certificate: x509.X509Certificate): string {
    return certificate.subjectName.getField('CN')[0];
  }

  private static issuerName(certificate: x509.X509Certificate): string {
    return certificate.issuerName.getField('CN')[0];
  }

  private static certificateExportData(certificate: x509.X509Certificate, parent: string): CertificateRecordExportData {
    let additional_info = '';
    if ('namedCurve' in certificate.publicKey.algorithm) {
      additional_info = certificate.publicKey.algorithm.namedCurve as string;
    } else if ('modulusLength' in certificate.publicKey.algorithm) {
      additional_info = (certificate.publicKey.algorithm.modulusLength as number).toString();
    }
    return {
      subject: MeshApi.subjectName(certificate),
      pubkey_algorithm_name: certificate.publicKey.algorithm.name,
      pubkey_algorithm_additional_info: additional_info,
      signature_algorithm_hash: certificate.signatureAlgorithm.hash.name,
      creation: certificate.notBefore,
      expiration: certificate.notAfter,
      parent: parent,
    };
  }

  static readonly CERTIFICATE_PEM_SUFFIX: string = '-----END CERTIFICATE-----';

  async getCertificates(): Promise<CertificateRecords> {
    let tokens = authService.getTokens();
    let urlBase = process.env.REACT_APP_MESH_API_URL;
    if (!tokens) {
      throw new Error('No tokens available');
    }
    let id = await this.getFactoryLinkCode(tokens);
    let getData = await fetch(`${urlBase}/entities/factory/${id}/certificate_records`, {
      headers: {
        Authorization: `Bearer ${tokens.idToken}`,
      },
    });
    let records = (await getData.json()) as CertificateRecordIntermediateData[];
    let result = { end_entities: {}, intermediates: {}, root: {} } as CertificateRecords;
    records.forEach(record => {
      let raw_components = record.cert.split(MeshApi.CERTIFICATE_PEM_SUFFIX);
      let components = raw_components.slice(0, -1); // remove the trailing empty component
      let certs: x509.X509Certificate[] = components.map(component => {
        component = component + MeshApi.CERTIFICATE_PEM_SUFFIX;
        return new x509.X509Certificate(component);
      });
      let parent_name = MeshApi.issuerName(certs[0]);
      result.end_entities[record.display_name] = MeshApi.certificateExportData(certs[0], parent_name);
      for (let i = 1; i < certs.length; i++) {
        let subject_name = MeshApi.subjectName(certs[i]);
        let parent_name = MeshApi.issuerName(certs[i]);
        if (subject_name !== parent_name && !(subject_name in result.intermediates)) {
          result.intermediates[subject_name] = MeshApi.certificateExportData(certs[i], parent_name);
        } else if (subject_name === parent_name && !(subject_name in result.root)) {
          result.root[subject_name] = MeshApi.certificateExportData(certs[i], parent_name);
        }
      }
    });
    return result;
  }
}

const api = new MeshApi();
export default api;

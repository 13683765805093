import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, NavLink, useNavigate, useLocation } from 'react-router-dom';
import authService from './AuthService';

import Deployments from './Deployments';
import Certificates from './Certificates';
import DeleteHumans from './DeleteHumans';
import Callback from './Callback';
import Builds from './Builds';
import Configuration from './Configuration';
import RotateKeys from './RotateKeys';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async () => {
    authService.login();
  };

  const handleLogout = () => {
    authService.logout();
    setIsLoggedIn(false);
    setShowLoginButton(true);
    navigate('/');
  };

  useEffect(() => {
    const checkLogin = async () => {
      await authService.loadConfig();
      const tokens = authService.getTokens();
      if (tokens) {
        setIsLoggedIn(true);
        setShowLoginButton(false);
      } else {
        setShowLoginButton(true);
      }
    };
    checkLogin();
  }, [location]);

  return (
    <div>
      <header className="header">
        <nav className="nav-bar">
          <ul className="nav-list">
            <li>
              {isLoggedIn ? (
                <NavLink
                  to="/deployments"
                  className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}
                >
                  Deployments
                </NavLink>
              ) : (
                <span className="nav-button disabled">Deployments</span>
              )}
            </li>
            <li>
              {isLoggedIn ? (
                <NavLink to="/builds" className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}>
                  Builds
                </NavLink>
              ) : (
                <span className="nav-button disabled">Builds</span>
              )}
            </li>
            <li>
              {isLoggedIn ? (
                <NavLink
                  to="/certificates"
                  className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}
                >
                  Certificates
                </NavLink>
              ) : (
                <span className="nav-button disabled">Certificates</span>
              )}
            </li>
            <li>
              {isLoggedIn ? (
                <NavLink
                  to="/configuration"
                  className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}
                >
                  Configuration
                </NavLink>
              ) : (
                <span className="nav-button disabled">Builds</span>
              )}
            </li>
            <li>
              {isLoggedIn ? (
                <NavLink
                  to="/rotate_keys"
                  className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}
                >
                  Rotate Keys
                </NavLink>
              ) : (
                <span className="nav-button disabled">Rotate Keys</span>
              )}
            </li>
            <li>
              {isLoggedIn ? (
                <NavLink
                  to="/delete_humans"
                  className={({ isActive }) => (isActive ? 'nav-button active' : 'nav-button')}
                >
                  Delete Humans
                </NavLink>
              ) : (
                <span className="nav-button disabled">Delete Humans</span>
              )}
            </li>
          </ul>
        </nav>
        <div className="auth-buttons">
          {showLoginButton && (
            <button className="meshin-button" onClick={handleLogin}>
              Meshin
            </button>
          )}
          {isLoggedIn && (
            <button className="logout-button" onClick={handleLogout}>
              Meshout
            </button>
          )}
        </div>
      </header>
      <main className="App">
        <Routes>
          <Route path="/" element={<div></div>} />
          <Route path="/deployments" element={<Deployments />} />
          <Route path="/builds" element={<Builds />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/rotate_keys" element={<RotateKeys />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/delete_humans" element={<DeleteHumans />} />
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </main>
    </div>
  );
};

export default App;

import api, { DeploymentStatusesJsonData } from './MeshApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from './AuthService';

const Deployments = () => {
  const [data, setData] = useState<DeploymentStatusesJsonData[] | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data == null) {
          const data = await api.getDeployments();
          console.log(data);
          setData(data);
        }
      } catch (error) {
        console.log('Failed to fetch deployments:', error);
        authService.logout();
        navigate('/');
      }
    };

    fetchData();
  }, [data, navigate]);

  if (data == null) {
    return <div className="spinner"></div>;
  }
  return (
    <div className="container">
      {data.map(host => (
        <div className="host-container" key={host.host}>
          <p className="host-title">Host: {host.host}</p>
          <div className="deployment-header">
            <p>Actor</p>
            <p>Version</p>
            <p>Status</p>
          </div>
          {host.statuses.map(deployment => (
            <div className="deployment-info" key={deployment.actor_id}>
              <p>{deployment.actor_name}</p>
              <p>{deployment.container_version}</p>
              <p className={deployment.container_status?.alive ? 'status-alive' : 'status-dead'}>
                {deployment.container_status?.alive ? 'Alive' : 'Dead'}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Deployments;

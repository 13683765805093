import { useState } from 'react';
import api, { ConfigJsonData } from './MeshApi';

const Configuration = () => {
  const [agentName, setAgentName] = useState('');
  const [storedAtParent, setStoredAtParent] = useState(false);
  const [storeAtParent, setStoreAtParent] = useState(false);
  const [configData, setConfigData] = useState<ConfigJsonData[] | null>(null);
  const [newConfig, setNewConfig] = useState({
    name: '',
    value: '',
    isSecret: false,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState('');
  const [saveErrorMessage, setSaveErrorMessage] = useState('');

  const clearErrors = () => {
    setFetchErrorMessage('');
    setSaveErrorMessage('');
  };

  const handleFetchConfig = async () => {
    try {
      setIsFetching(true);
      setFetchErrorMessage('');
      const fetchedConfig = await api.getConfiguration(agentName, storedAtParent);
      setConfigData(fetchedConfig.config);
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      setFetchErrorMessage('Failed to fetch configuration. Please try again.');
      setConfigData([]);
    } finally {
      setIsFetching(false);
    }
  };

  const handleAddOrUpdateConfig = async () => {
    try {
      setIsSaving(true);
      setSaveErrorMessage('');
      await api.updateConfiguration(agentName, storeAtParent, newConfig.name, newConfig.value, newConfig.isSecret);
      await handleFetchConfig();
      setNewConfig({
        name: '',
        value: '',
        isSecret: false,
      });
    } catch (error) {
      console.error('Failed to update configuration:', error);
      setSaveErrorMessage('Failed to update configuration. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="configuration-container">
      <div className="form-group">
        <label htmlFor="agentName" className="form-label">
          Agent Name
        </label>
        <input
          type="text"
          id="agentName"
          className="form-control"
          value={agentName}
          onChange={e => {
            clearErrors();
            setAgentName(e.target.value);
          }}
          placeholder="Enter agent name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="storedAtParent" className="form-label">
          Stored at Parent
        </label>
        <div className="form-checkbox-container">
          <input
            type="checkbox"
            id="storedAtParent"
            checked={storedAtParent}
            onChange={e => {
              clearErrors();
              setStoredAtParent(e.target.checked);
            }}
            className="form-checkbox"
          />
        </div>
      </div>
      <button className="btn fetch-btn" onClick={handleFetchConfig} disabled={isFetching || !agentName}>
        {isFetching ? 'Loading...' : 'Fetch Configuration'}
      </button>
      {fetchErrorMessage && <div className="alert error-message">{fetchErrorMessage}</div>}
      {configData && (
        <div className="config-table-container">
          <h2>Current Configuration</h2>
          <table className="config-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>Secret</th>
              </tr>
            </thead>
            <tbody>
              {configData.map((config, index) => (
                <tr key={index}>
                  <td>{config.name}</td>
                  <td>{config.value}</td>
                  <td>{config.is_secret ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="add-config-form">
        <h2>Add or Update Configuration</h2>
        <div className="form-group">
          <label htmlFor="configName" className="form-label">
            Name
          </label>
          <input
            type="text"
            id="configName"
            className="form-control"
            value={newConfig.name}
            onChange={e => {
              clearErrors();
              setNewConfig({ ...newConfig, name: e.target.value });
            }}
            placeholder="Enter configuration name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="configValue" className="form-label">
            Value
          </label>
          <input
            type="text"
            id="configValue"
            className="form-control"
            value={newConfig.value}
            onChange={e => {
              clearErrors();
              setNewConfig({ ...newConfig, value: e.target.value });
            }}
            placeholder="Enter configuration value"
          />
        </div>
        <div className="form-group">
          <label htmlFor="storeAtParent" className="form-label">
            Store at Parent
          </label>
          <div className="form-checkbox-container">
            <input
              type="checkbox"
              id="storeAtParent"
              checked={storeAtParent}
              onChange={e => {
                clearErrors();
                setStoreAtParent(e.target.checked);
              }}
              className="form-checkbox"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="isSecret" className="form-label">
            Secret
          </label>
          <div className="form-checkbox-container">
            <input
              type="checkbox"
              id="isSecret"
              checked={newConfig.isSecret}
              onChange={e => {
                clearErrors();
                setNewConfig({ ...newConfig, isSecret: e.target.checked });
              }}
              className="form-checkbox"
            />
          </div>
        </div>
        <button className="btn submit-btn" onClick={handleAddOrUpdateConfig} disabled={isSaving || !newConfig.name}>
          {isSaving ? 'Submitting...' : 'Submit'}
        </button>
        {saveErrorMessage && <div className="alert error-message">{saveErrorMessage}</div>}
      </div>
    </div>
  );
};

export default Configuration;

import { useState } from 'react';
import api, { GetRotateKeysResponseData } from './MeshApi';

const RotateKeys = () => {
  const [trusteeName, setTrusteeName] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState('');
  const [saveErrorMessage, setSaveErrorMessage] = useState('');
  const [rotateData, setRotateData] = useState<GetRotateKeysResponseData | null>(null);
  const [rotateStemCellKey, setRotateStemCellKey] = useState(false);
  const [rotateTrusteeStemId, setRotateTrusteeStemId] = useState(false);

  const clearErrors = () => {
    setFetchErrorMessage('');
    setSaveErrorMessage('');
  };

  const formatDate = (date?: Date) => (date ? new Date(date).toLocaleString() : '');

  const handleFetchStatus = async () => {
    try {
      setIsFetching(true);
      setFetchErrorMessage('');
      const fetchedData = await api.getRotateKeysStatus(trusteeName);
      setRotateData(fetchedData);
    } catch (error) {
      console.error('Failed to fetch configuration:', error);
      setFetchErrorMessage('Failed to fetch configuration. Please try again.');
      setRotateData(null);
    } finally {
      setIsFetching(false);
    }
  };

  const handleRotateKeys = async () => {
    try {
      setIsSaving(true);
      setSaveErrorMessage('');
      await api.rotateKeys(trusteeName, rotateStemCellKey, rotateTrusteeStemId);
      await handleFetchStatus();
    } catch (error) {
      console.error('Failed to rotate keys:', error);
      setSaveErrorMessage('Failed to rotate keys. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="configuration-container">
      <div className="form-group">
        <label htmlFor="trusteeName" className="form-label">
          Trustee Name
        </label>
        <input
          type="text"
          id="trusteeName"
          className="form-control"
          value={trusteeName}
          onChange={e => {
            clearErrors();
            setTrusteeName(e.target.value);
          }}
          placeholder="Enter truste name"
        />
      </div>
      <button className="btn fetch-btn" onClick={handleFetchStatus} disabled={isFetching || !trusteeName}>
        {isFetching ? 'Loading...' : 'Fetch Status'}
      </button>
      {fetchErrorMessage && <div className="alert error-message">{fetchErrorMessage}</div>}
      {rotateData && (
        <div className="response-container">
          <h1 className="title">Rotate Keys Response</h1>
          <div className="card">
            <h2>Trustee Stem ID Status</h2>
            <p>Current Version: {rotateData.trustee_stem_id_status.current_version}</p>
            <p>
              Stem ID Last Update Timestamp:{' '}
              {formatDate(rotateData.trustee_stem_id_status.stem_id_last_update_timestamp)}
            </p>
            {rotateData.trustee_stem_id_status.rotation_status && (
              <div className="sub-card">
                <h3>Rotation Status</h3>
                <p>Node Rotating: {rotateData.trustee_stem_id_status.rotation_status.node_rotating}</p>
                <p>Started At Timestamp: {rotateData.trustee_stem_id_status.rotation_status.started_at_timestamp}</p>
                <p>Last Status Timestamp: {rotateData.trustee_stem_id_status.rotation_status.last_status_timestamp}</p>
              </div>
            )}
          </div>

          <div className="card">
            <h2>Trustee Stem Cell Key Last Update</h2>
            <p>{formatDate(rotateData.trustee_stem_cell_key_last_update_timestamp)}</p>
          </div>

          <div className="card">
            <h2>Entity Trustee Stem ID Status</h2>
            {rotateData.entity_trustee_stem_id_status ? (
              <>
                <p>Current Version: {rotateData.entity_trustee_stem_id_status.current_version}</p>
                <p>
                  Stem ID Last Update Timestamp:{' '}
                  {formatDate(rotateData.entity_trustee_stem_id_status.stem_id_last_update_timestamp)}
                </p>
              </>
            ) : (
              <p>No data available.</p>
            )}
          </div>

          <div className="card">
            <h2>Entity Trustee Entity Stem IDs Status</h2>
            {rotateData.entity_trustee_entity_stem_ids_status ? (
              <>
                <p>Node Rotating: {rotateData.entity_trustee_entity_stem_ids_status.node_rotating}</p>
                <p>Started At Timestamp: {rotateData.entity_trustee_entity_stem_ids_status.started_at_timestamp}</p>
                <p>Last Status Timestamp: {rotateData.entity_trustee_entity_stem_ids_status.last_status_timestamp}</p>
              </>
            ) : (
              <p>No data available.</p>
            )}
          </div>

          <div className="card">
            <h2>Entity Trustee Stem Cell Key Last Update</h2>
            <p>{formatDate(rotateData.entity_trustee_stem_cell_key_last_update_timestamp)}</p>
          </div>
        </div>
      )}
      <div className="add-config-form">
        <h2>Rotate Key</h2>
        <div className="form-group">
          <label htmlFor="rotateStemCellKey" className="form-label">
            Stem Cell Key
          </label>
          <div className="form-checkbox-container">
            <input
              type="checkbox"
              id="rotateStemCellKey"
              checked={rotateStemCellKey}
              onChange={e => {
                clearErrors();
                setRotateStemCellKey(e.target.checked);
              }}
              className="form-checkbox"
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="rotateTrusteeStemId" className="form-label">
            Stem Ids
          </label>
          <div className="form-checkbox-container">
            <input
              type="checkbox"
              id="rotateTrusteeStemId"
              checked={rotateTrusteeStemId}
              onChange={e => {
                clearErrors();
                setRotateTrusteeStemId(e.target.checked);
              }}
              className="form-checkbox"
            />
          </div>
        </div>
        <button
          className="btn submit-btn"
          onClick={handleRotateKeys}
          disabled={isSaving || (!rotateStemCellKey && !rotateTrusteeStemId)}
        >
          {isSaving ? 'Submitting...' : 'Submit'}
        </button>
        {saveErrorMessage && <div className="alert error-message">{saveErrorMessage}</div>}
      </div>
    </div>
  );
};

export default RotateKeys;

import api, { BuildInformationJsonData } from './MeshApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from './AuthService';

const fetchLimit = 25;

const Builds = () => {
  const [data, setData] = useState<BuildInformationJsonData[] | null>(null);
  const [hasPrevious, setHasPrevious] = useState(true);
  const [hasNext, setHasNext] = useState(true);
  const [offset, setOffset] = useState(0);

  const navigate = useNavigate();

  const handleRetry = async (commitSha: string) => {
    const confirmation = window.confirm(`Are you sure you want to retry the build for commit SHA: ${commitSha}?`);
    if (confirmation) {
      try {
        await api.resubmitBuilt(commitSha);
        alert(`Build retry submitted successfully for commit SHA: ${commitSha}`);
      } catch (error) {
        alert(`An error occurred while retrying the build: ${error}`);
      }
    }
  };

  const handlePreviousPage = async () => {
    if (offset > fetchLimit) {
      setOffset(offset - fetchLimit);
    } else if (offset > 0) {
      setOffset(0);
    }
  };

  const handleNextPage = async () => {
    if (data) {
      setOffset(offset + data.length);
    }
    console.log(offset);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.getBuilds(offset, fetchLimit);
        console.log(data);
        setData(data);
        setHasPrevious(offset > 0);
        setHasNext(data.length === fetchLimit);
      } catch (error) {
        console.log('Failed to fetch builds:', error);
        authService.logout();
        navigate('/');
      }
    };

    fetchData();
  }, [offset, navigate]);

  if (data == null) {
    return <div className="spinner"></div>;
  }
  return (
    <div className="container mt-4">
      <table className="builds-table">
        <thead>
          <tr>
            <th>Commit SHA</th>
            <th>Commit Message</th>
            <th>Status</th>
            <th>Last Status Update</th>
            <th>Last Status Message</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map(build => (
            <tr key={build.commit_sha}>
              <td className="text-break">{build.commit_sha}</td>
              <td className="text-break">{build.commit_message}</td>
              <td>
                <span className={`status-badge ${build.status === 'Building' ? 'building' : (build.status === 'Success' ? 'success' : 'failed')}`}>
                  {build.status}
                </span>
              </td>
              <td>{build.last_status_update ? new Date(build.last_status_update).toLocaleString() : ''}</td>
              <td>{build.last_status_message || ''}</td>
              <td>
                {build.status === 'Failed' && (
                  <button className="retry-btn" onClick={() => handleRetry(build.commit_sha)}>
                    Retry
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button className="pagination-btn" onClick={handlePreviousPage} disabled={!hasPrevious}>
          Previous
        </button>
        <button className="pagination-btn" onClick={handleNextPage} disabled={!hasNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Builds;
